















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class PopupMenuBody extends Vue {
  @Prop({type: Array, default: () => ([])}) items?: any[]
  @Prop({type: Boolean, default: false}) right?: boolean
  @Prop({type: Boolean, default: false}) small?: boolean
  @Prop({type: Number}) menuTop?: number
  @Prop({type: Number}) menuLeft?: number
  @Prop({type: Number}) menuRight?: number

  get upDown() {
    if (this.menuTop && this.menuTop > window.innerHeight / 2) {
      return 'up'
    }
    return 'down'
  }

  get popupClass() {
    let classList: string[] = [this.upDown]
    this.right ? classList.push('right') : classList.push('left')
    this.small ? classList.push('small') : classList.push('offset')
    return classList.join(' ')
  }

  get style() {
    if (this.upDown === 'down') {
      return { top: `${this.menuTop}px` }
    } else {
      const bottom: number = window.innerHeight - this.menuTop! + 35
      return { bottom: `${bottom}px` }
    }
  }

  get styleLeft() {
    return {
      left: `${this.menuLeft}px`
    }
  }

  get styleRight() {
    return {
      right: `calc(100vw - ${this.menuRight}px)`
    }
  }
}
