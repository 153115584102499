<template>
  <v-row class="fill-height flex-column" justify="center" no-gutters>
    <div class="mx-auto" style="display: inline-block;">
      <v-img class="mx-auto my-8"
             src="../assets/pigeon_logo.svg"
             max-width="136"
             max-height="104"
      />
      <div class="mx-auto text-not-found">
        Content Not Found
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "content-not-found"
}
</script>

<style scoped>
.text-not-found {
  color: #364462;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
</style>
