







import {Component, Vue, Prop } from 'vue-property-decorator';

@Component({name: 'gp-checkbox'})
export default class CheckboxView extends Vue {
  @Prop({type: Boolean, default: false}) readonly checked;
  @Prop({type: String}) readonly id: string | undefined;
};
