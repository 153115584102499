




















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'vc-switch'})
export default class VCSwitch extends Vue {
  @Prop() value;
  @Prop({default: 'id-switch'}) id;
  @Prop({default: false}) disable?: boolean;
  @Prop() label?: string;
  @Prop({default: false}) visible?: boolean;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }
}
