


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {applicationStore} from '@/store/modules/application';
import {businessStore} from '@/store/modules/business/businessStore';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import { profileStore } from '@/store/modules/profile';
import constants from '@/common/constants';

@Component({
    name: 'dialog-setup-status'
})
export default class DialogSetupStatus extends Vue {
    @Prop() value!: boolean; 
    tempValue !: boolean;
    
    data() {
        return {
            tempValue: this.value,
        };
    }
    
    onCloseDialogSetupStatus() {
        this.$emit('close-dialog');
        this.tempValue = false;
    }

    onReadSetup(){                        
        //this.onCloseDialogSetupStatus();            
        this.$emit('on-read-setup')
    }

    // status
    get createAccountDone() {
        return true;
    }

    get inviteDone() {
        return !!businessStore.invited?.length;
    }

    get createBusinessDone() {
        return !!directoryStore.directoryContactsCount;
    }

    get completeProfileDone() {
        return !!applicationStore?.business?.zipCode;
    }

    get discoverDone() {    
        return !!profileStore.discoverd;
    }

    get completedCount(){    
        return Number(this.createAccountDone) + Number(this.inviteDone) + Number(this.createBusinessDone) 
        + Number(this.completeProfileDone) + Number(this.discoverDone);
    }
    get isCompleted() {
        return this.completedCount == 5;
    }

    get completedText() {
        return this.completedCount + ' of 5 completed'
    }


    get circularStyle() {
        return {
            '--progress': this.completedCount * 20
        }
    }
}
