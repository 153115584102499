



































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'group-avatar'})
export default class GroupAvatar extends Vue {
  @Prop({type: [Number, String], default: 48}) size;
  @Prop({
    default: () => {
      return [];
    }
  }) images?: string[];

  get radius(): number {
    return this.size! / 2;
  }

  get style() {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`
    };
  }

  get topLeftStyle() {
    return {
      borderTopLeftRadius: `${this.radius}px`
    };
  }

  get bottomLeftStyle() {
    return {
      borderBottomLeftRadius: `${this.radius}px`
    };
  }

  get topRightStyle() {
    return {
      borderTopRightRadius: `${this.radius}px`
    };
  }

  get bottomRightStyle() {
    return {
      borderBottomRightRadius: `${this.radius}px`
    };
  }

  get one() {
    return this.images!.length >= 1;
  }

  get two() {
    return this.images!.length >= 2;
  }

  get three() {
    return this.images!.length >= 3;
  }

  get four() {
    return this.images!.length >= 4;
  }

  get oneSizeWidth() {
    if (this.one) {
      if (this.four || this.three || this.two) {
        return this.size! / 2;
      } else {
        return this.size;
      }
    } else {
      return 0;
    }
  }

  get oneSizeHeight() {
    if (this.one) {
      if (this.four) {
        return this.size! / 2;
      } else {
        return this.size;
      }
    } else {
      return 0;
    }
  }

  get twoSizeWidth() {
    if (this.two) {
      return this.size! / 2;
    } else {
      return 0;
    }
  }

  get twoSizeHeight() {
    if (this.two) {
      if (this.three) {
        return this.size! / 2;
      } else {
        return this.size;
      }
    } else {
      return 0;
    }
  }
}
