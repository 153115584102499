
















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import BaseDialog from '@/components/BaseDialog.vue';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';

@Component({
    name: 'dialog-video'
})
export default class DialogVideo extends Vue {
    @Prop() value!: boolean;        
    tempValue !: boolean;

    data() {
        return {
            tempValue: this.value,
        };
    }

    get videoSrc() {
        if(this.$route.path.includes('directory')){                
            return require('@/assets/video_directory_setup.svg');       
        }
        if(this.$route.path.includes('management')){
            return require('@/assets/video_invite_member.svg');
        }
        return "";
    }

    onCloseVideoDialog() {
        this.$emit('close-dialog');
        this.tempValue = false;
    }
}
