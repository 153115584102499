









import {Component, Vue} from 'vue-property-decorator';
@Component({
  name: 'page-not-found'
})
export default class PageNotFound extends Vue {
  public showBtn: boolean = true
  get title() {
     switch (this.$route.name) {
        case 'active-not-found':
        case 'inner-not-found':
          this.showBtn = false
          return 'Oops! Chat not found.'
        default:
          return 'Oops! Page not found.'
     }
  }
}
