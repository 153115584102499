








import {Component, Vue} from 'vue-property-decorator';

@Component({name: 'gp-header'})
export default class HeaderView extends Vue {
  hasActionSlot() {
    return !!this.$slots['action'];
  }
};
