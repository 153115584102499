




















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import {Associate} from '@/domain/model/associate';
import {businessStore} from '@/store/modules/business/businessStore';
import BusinessStoreBase from '@/components/BusinessStoreBase.vue';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from "@/store/modules/profile";
import {twoChars} from "@/utils/helpers";
import {storage} from "@/plugins/firebase.init";
import {Roles} from "@/domain/model/types";

@Component({
  name: 'dialog-edit-associate-profile',
  components: {VcSwitch, GroupAvatar, AvatarWithStatus, BaseDialog},
  mixins: [BusinessStoreBase]
})
export default class DialogEditAssociateProfile extends Vue {
  @Prop() value!: boolean;
  @Prop() user: any;

  id: string = '';
  name: string = '';
  position: string = '';
  email: string = '';
  phoneNumber: string = '';
  role: any;
  roles: any[] = [
    {name: 'Associate', value: 1},
    {name: 'Admin', value: 3}
  ];

  associateImageFile: File | null = null;
  associateImageUrl: string | null = null;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get business() {
    return applicationStore.business
  }

  get avatar() {
    return this.associateImageUrl || (this.user!.photoUrl && this.user!.photoUrl.thumbnail);
  }

  get avatarPhotoChanged() {
    return !!this.associateImageFile && !!this.associateImageUrl;
  }

  onAssociateImageFileSelected(event) {
    this.associateImageFile = event.target.files[0];
    if (this.associateImageFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.associateImageUrl = e.target.result || null;
      };
      reader.readAsDataURL(this.associateImageFile);
    }
  }

  async onSave() {
    const form: any = this.$refs.userProfile;
    if (!form.validate()) {
      return;
    }
    const {id, name, email, position, phoneNumber, role } = this;
    const user = {
      id,
      name,
      email,
      position,
      phoneNumber,
      role: role.value,
      business: {id: this.user.business.id, name: this.user.business.name}};
    const ok = await businessStore.editUser({user, profilePhoto: this.associateImageFile});
    this.show = !ok;
  }

  init() {
    this.id = this.user.id;
    this.name = this.user.fullName;
    this.position = this.user.position;
    this.email = this.user.email;
    this.phoneNumber = this.user.phoneNumber;
    this.role = this.user.roles.admin? this.roles[1] : this.roles[0];
  }

  created() {
    this.init();
  }

  updated() {
    this.init();
  }
}
