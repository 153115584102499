


















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {applicationStore} from '@/store/modules/application';
import {businessStore} from '@/store/modules/business/businessStore';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import { profileStore } from '@/store/modules/profile';
import constants from '@/common/constants';

interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}


@Component({
  name: 'dialog-info-center',
  components: {BaseDialog}
})
export default class DialogInfoCenter extends Vue {
  @Prop() value!: boolean
  tempValue !: boolean;

  selectedSrc = '../assets/_selected.svg';
  isScroll  = false;
  helpCenterLink = constants.PIGEON_PLATFORM_HELP_CENTER;

  // setup items
  setupItems = [
    {
      title1: "Create",
      title2: "the Pigeon account",
      icon: require('../assets/_selected.svg'), 
      step: "1"
    },
    {
      title1: "Invite",
      title2: "team members",
      onClick: this.onInvite,
      icon: !!businessStore.invited?.length ? require('../assets/_selected.svg') : '', 
      step: "2"
    },
    {
      title1: "Create",
      title2: "a business contact",
      icon: !!directoryStore.directoryContactsCount ? require('../assets/_selected.svg') : '', 
      onClick: this.onCreateBusiness,      
      step: "3"
    },
    {
      title1: "Complete",
      title2: "company profile",
      icon: !!applicationStore?.business?.zipCode ? require('../assets/_selected.svg') : '', 
      onClick: this.onCompleteProfile,
      step: "4"
    },
    {
      title1: "Discover",
      title2: "Pigeon",
      icon: !!profileStore.discoverd ? require('../assets/_selected.svg') : '', 
      onClick: this.onDiscover,
      step: "5"
    }
  ];
   

  // additional items
  additionalItems = [
        {
          imgSrc: require('../assets/_box_contact.svg'),
          title: "Create business directory",
          subtitle: "Add business departments, group and customer contacts",
          onClick: this.onUnleashBusiness
        },
        {
          imgSrc: require('../assets/_box_email.svg'),
          title: "Set up email notifications",
          subtitle: "Stay up to date with cases and set up notifications manager",
          onClick: this.onUnleashEmail
        },
        {
          imgSrc: require('../assets/_box_live_chat.svg'),
          title: "Sync with Pigeon Live Chat",
          subtitle: "Add Pigeon Live Chat on your website",
          onClick: this.onUnleashLiveChat
        },
        {
          imgSrc: require('../assets/_box_schedule.svg'),
          title: "Add business working hours",
          subtitle: "Select working hours for your business",
          onClick: this.onUnleashSchedule
        },
        {
          imgSrc: require('../assets/_box_appointment.svg'),
          title: "Set up appointments",
          subtitle: "Set hours and select a default appointment manager",
          onClick: this.onUnleashAppointment
        },
        {
          imgSrc: require('../assets/_box_setting.svg'),
          title: "Create automated messages",
          subtitle: "Set default messages to inform your customers",
          onClick: this.onUnleashSetting
        },
        {
          imgSrc: require('../assets/_box_backup_manager.svg'),
          title: "Set up Backup manager",
          subtitle: "Make sure there is always someone available to answer",
          class: "last-item",
          onClick: this.onUnleashBackupManager
        }
  ]
 
  data() {
    return {
        tempValue: this.value,
    };
  }

  handleClick(item) {
    if (item.icon === '' && item.onClick) { // Check if the item is enabled
      item.onClick(); // Call the associated click handler
    }
  }

  // on clicks setup items
  onInvite(){
    this.$router.push({
      path: 'users',
      name: 'users', 
      query: {tab: '0'},
      params: {isInfoItems: '1'}
    });
    this.hide();
  }

  onCreateBusiness(){    
    this.$router.push({
      path: 'directory',
      name: 'directory', 
      params: {isInfoItems: '1'}
    });
    this.hide();
  }

  onCompleteProfile(){    
    this.$router.push({
      path: 'company',
      name: 'company-profile', 
      params: {isInfoItems: '1'}
    });

    this.hide();    
  }

  onDiscover(){  
    this.$router.push({
      path: 'get-started',
      name: 'get-started', 
      params: {isInfoItems: '1'}
    });
    this.hide();
  }


  // on clicks additional items
  onUnleashBusiness(){
    this.$router.push({
      path: 'directory',
      name: 'directory'
    });
    this.hide();
  }

  onUnleashEmail(){
    this.$router.push({
      path: 'notifications',
      name: 'settings-notifications'
    });
    this.hide();
  }

  onUnleashLiveChat(){
    this.$router.push({
      path: 'business-live-chat',
      name: 'settings-live-chat'
    });
    this.hide();
  }

  onUnleashSchedule(){
    this.$router.push({
      path: 'schedules',
      name: 'settings-schedules'
    });
    this.hide();
  }

  onUnleashAppointment(){
    this.$router.push({
      path: 'appointments',
      name: 'settings-appointments'
    });
    this.hide();
  }

  onUnleashSetting(){
    this.$router.push({
      path: 'away-messages',
      name: 'settings-away-messages'
    });
    this.hide();
  }

  onUnleashBackupManager(){
    this.$router.push({
      path: 'backup',
      name: 'settings-backup'
    });
    this.hide();
  }
  
  hide(){   
    this.tempValue = false;  
    setTimeout(() => {
      this.$emit('input', false)
    }, 1000)
  } 
  
  get isOldUser() {
    // for new users only,  November 1, 2024    
    const createdDate = applicationStore?.business?.createdDate as FirestoreTimestamp | null;    
    if (!createdDate || typeof createdDate.seconds === 'undefined' || typeof createdDate.nanoseconds === 'undefined') {
      return true;
    }

    const dateToCompare = new Date(createdDate?.seconds * 1000 + createdDate?.nanoseconds / 1e6); 
    const validFrom =  new Date('2024-11-01T00:00:00Z');
    return dateToCompare < validFrom;
  }

  // status
  get createAccountDone() {
    return true;
  }

  get inviteDone() {
    return !!businessStore.invited?.length;
  }

  get createBusinessDone() {
    return !!directoryStore.directoryContactsCount;
  }

  get completeProfileDone() {
    return !!applicationStore?.business?.zipCode;
  }

  get discoverDone() {    
    return !!profileStore.discoverd;
  }

  get completedCount(){     

    return Number(this.createAccountDone) 
      + Number(!!businessStore.invited?.length) 
      + Number(!!directoryStore.directoryContactsCount) 
      + Number(!!applicationStore?.business?.zipCode) 
      + Number(!!profileStore.discoverd);
  }

  get isCompleted() {
    return this.completedCount == 5 || this.isOldUser;
  }

  get completedText() {
    return this.completedCount + ' of 5 completed'
  }

  get circularStyle() {
      return {
        '--progress': this.completedCount * 20
      }
  }

  get show() {
    return this.tempValue
  } 

  set show(value: boolean) {
    this.$emit('input', value)
  }
}
