





























































































import {Component, Prop, Watch} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {businessStore} from '@/store/modules/business/businessStore';
import BusinessStoreBase from '@/components/BusinessStoreBase.vue';
import {Invite} from '@/domain/model/invite';
import {auth} from '@/plugins/firebase.init';
import ToolTip from '@/components/custom/ToolTip.vue';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import TextFieldEmail from '@/components/profile/privacy-security/TextFieldEmail.vue';
import {fetchSignInMethodsForEmail} from 'firebase/auth';


@Component({
  name: 'dialog-send-invite',
  components: {TextFieldEmail, GroupAvatar, AvatarWithStatus, BaseDialog, ToolTip},
  mixins: [BusinessStoreBase]
})
export default class DialogSendInvite extends mixins(Notifications) {
  @Prop() value!: boolean;
  role: any;
  roles: any[] = [
    {name: 'Associate', value: 1},
    {name: 'Admin', value: 3}
  ];
  doubleEmail: any = {}
  existsEmail: string[] = []
  invites: Invite[] = [
    new Invite(0, '', '', 'signUp'),
  ];

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get hint(){
    return 'Select role';
  }

  get allowAddInvite() {
    return this.invites.length < 7;
  }

  @Watch('invites', {deep: true})
  onChangeEmails(after) {
    this.existsEmail = []
    this.doubleEmailCounter(after)
  }

  doubleEmailCounter(items) {
    this.doubleEmail = {}
    items.forEach(item => {
      const email = item.email
      if (email) {
        if (this.doubleEmail.hasOwnProperty(email)) {
          this.doubleEmail[email] = this.doubleEmail[email] + 1
        } else {
          this.doubleEmail[email] = 1
        }
      }
    })
  }

  validateDoubleEmail(v) {
    return !v || this.doubleEmail[v] <= 1 || 'Double email'
  }

  onAddInviteRow() {
    this.invites.push(new Invite(this.invites.length, '', '', 'signUp'));
  }

  onRemoveInviteRow(item) {
    this.invites.splice(this.invites.findIndex((invite) => invite.index === item.index), 1);
  }

  async onSendInvites() {
    const form: any = this.$refs.formInvites;
    if (form.validate()) {
      this.existsEmail = []
      for (const invite of this.invites) {
        const providers = await fetchSignInMethodsForEmail(auth, invite.email)
        if (providers.length) {
          this.existsEmail.push(invite.email)
          this.showIssue(`Email ${invite.email} already exists`)
        }
      }
      if (this.existsEmail.length === 0) {
        const ok = await businessStore.inviteUsers(this.invites);
        this.show = !ok;
      }
    }
  }
}
