




















































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {BusinessDepartment} from '@/domain/model/types';

@Component({
  name: 'dialog-confirm',
  components: {BaseDialog}
})
export default class DialogConfirm extends Vue {
  // todo: add props for: width, height, max-width
  @Prop() value!: boolean
  @Prop() title!: string
  @Prop() text?: string
  @Prop() image?: any
  @Prop() files?: any
  @Prop({default: ''}) textColor?: string
  @Prop({default: 'error'}) btnColor?: string
  @Prop({default: 'Ok'}) confirm?: string
  @Prop({default: true}) cancelShow?: boolean

  progresses:any = []

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get srcImage() {
    const screenWindow: any = window
    const _URL = screenWindow.URL || screenWindow.webkitURL
    return _URL.createObjectURL(this.image)
  }

  get checkDefaultSlot() {
    return !!this.$slots.default
  }

  getFileProgress(file:any,index:any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    var percent = 0
    let id = 'progress-' + index
    reader.addEventListener('progress', (event): number | void => {
          if (event.loaded && event.total) {
            percent = (event.loaded / event.total) * 100;
            const progress = (<HTMLInputElement>document.getElementById(id))
            progress.value = percent.toString()
            if (percent === 100) {
              progress.hidden = true
            } 
          }  
    })
  }

  removeAttachedFile(index:any){
    const dt = new DataTransfer()
    
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i]
      if (index !== i)
        dt.items.add(file)
    }
  
    this.files = dt.files

    if(this.files?.length == 0){
      this.onCancel()
    }else if(this.files.length > 1) {
      this.title = this.files.length + ' files';
    }else {
      this.title = this.files.length + ' file';
    }
  }

  onCancel() {
    this.show = false
    this.$emit('on-cancel')
  }

  async onConfirm() {
    this.show = false
    if (this.files){
      this.$emit('on-confirm',this.files)
      return
    }
    this.$emit('on-confirm')
  }
}
