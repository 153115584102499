






import {Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({name: 'gp-checkbox-list'})
export default class CheckboxListView extends Vue {
  @Prop({type: String}) readonly status?;

  toggle: boolean = false;

  @Watch('toggle')
  onToggle(val: string, oldVal: string) {
    this.$emit('check-list');
  }
};
