




import {Component, Vue} from 'vue-property-decorator';
import {businessStore} from '@/store/modules/business/businessStore';

@Component({name:'business-store-base'})
export default class BusinessStoreBase extends Vue{
    get saving() {
        return businessStore.saving;
    }
}
