import store from '@/store'
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {profileStore} from '@/store/modules/profile'
import {applicationStore} from '@/store/modules/application'
import {auth} from '@/plugins/firebase.init'
import axios from '@/plugins/axios'
import {BusinessContact, RolesList} from '@/domain/model/types'

const defaultTourProperty = {
  emptyTour: {
    text: '',
    querySelector: {
      relative: null,
      selected: null
    },
    router: {name: '', params: {}, query: {}},
    dynamicRouter: false,
    backgrounds: [],
    position: {},
    arrow: {side: '', position: ''},
    action: false,
    next: ''
  },
  superAdmin: [
    // 1
    {
      text:
        'This is your Requests section, where new<br>' +
        'customer requests will appear.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(2)'
      },
      router: {name: 'inbox', params: {}, query: {type: 'inbox', subtype: 'all'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 2
    {
      text:
        'This is your Chats section where all ongoing<br>' +
        'conversations or cases will appear. After you<br>' +
        'or a teammate accepts a request, it<br>' +
        'automatically moves to chats.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(3)'
      },
      router: {name: 'active-chat', params: {}, query: {type: 'active', subtype: 'personal'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 3
    {
      text:
        'This is your Contacts section. Contacts will<br>' +
        'appear in your business directory and will be<br>' +
        'used so your associates and customers can<br>' +
        'interact with the right individuals. Get started<br>' +
        'by creating contacts for yourself and your<br>' +
        'teammates!',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(4)'
      },
      router: {name: 'directory', params: {}, query: {}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 4
    {
      text:
        'First, create an individual contact for each<br>' +
        'teammate. You will also be able to create<br>' +
        'group contacts for multiple individuals.',
      querySelector: {
        relative: '.sidebar-contacts',
        selected: '.sidebar-contacts > header button'
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {x: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: -10}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 5
    {
      text:
        'View and edit your Business Directory in this<br>' +
        'window. Starting organizing it by creating<br>' +
        'departments and sub-departments.',
      querySelector: {
        relative: '[data-block=directoryTree]',
        selected: null
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {left: -410}, top: 100},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 6
    {
      text:
        'As an Admin, you can also access the Reports<br>' +
        'section for your company’s KPIs, performance<br>' +
        'data, and insights. Open and closed cases are<br>' +
        'also tracked here. Data is updated every night.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(5)'
      },
      router: {name: 'dashboard', params: {}, query: {}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 7
    {
      text:
        'This is your Business section where you can<br>' +
        'see and manage what teammates are part of<br>' +
        'this workspace, as well as manage your<br>' +
        'company profile.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(6)'
      },
      router: {name: 'users', params: {}, query: {tab: '0'}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 8
    {
      text:
        'To invite new teammates, you can use one of<br>' +
        'these options: Invite by email or Add manually.<br>' +
        'The main difference is Add manually requires<br>' +
        'more information up front by you, whereas<br>' +
        'Invite by email only requires you to enter their<br>' +
        'email. With both, the users still need to accept<br>' +
        'their invite and create an account.',
      querySelector: {
        relative: '[data-block=usersTopAction]',
        selected: null
      },
      router: {name: 'users', params: {}, query: {tab: 0}},
      backgrounds: [
        {left: 0, width: {left: -10}, top: 0, bottom: 0},
        {left: {right: 10}, right: 0, top: 0, bottom: 0},
        {left: {left: -10}, width: {width: 20}, top: 0, height: {top: -10}},
        {left: {left: -10}, width: {width: 20}, top: {bottom: 10}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {left: -430}, top: {top: -5}},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 9
    {
      text:
        'Activated tab shows users who accepted<br>' +
        'your invite. Invited by email shows users who<br>' +
        'were invited by email but have not yet<br>' +
        'accepted the invite. Added manually shows<br>' +
        'users who you added manually but have not<br>' +
        'yet created their account.',
      querySelector: {
        relative: 'section.main > .tabs > nav > ul',
        selected: null
      },
      router: {name: 'users', params: {}, query: {tab: 0}},
      backgrounds: [
        {left: 0, width: {left: -10}, top: 0, bottom: 0},
        {left: {right: 10}, right: 0, top: 0, bottom: 0},
        {left: {left: -10}, width: {width: 20}, top: 0, height: {top: -10}},
        {left: {left: -10}, width: {width: 20}, top: {bottom: 10}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 25}, top: {top: -5}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 10
    {
      text:
        'Since you created a new business your role<br>' +
        'by default is Super Admin.',
      querySelector: {
        relative: '[data-super-admin=true]',
        selected: '[data-super-admin=true] > td:nth-child(3)'
      },
      router: {name: 'users', params: {}, query: {tab: 0}},
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: {left: 0}, width: {width: 0}, top: 0, height: {top: 0}},
        {left: {left: 0}, width: {width: 0}, top: {bottom: 0}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {_left: 0}, top: {bottom: 10}},
      arrow: {side: 'top', position: 'left'},
      action: false,
      next: 'Next'
    },
    // 11
    {
      text:
        'The Settings section allows you to edit<br>' +
        'settings like time zone, schedules, backup<br>' +
        'contacts and more.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(7)'
      },
      router: {name: 'settings-localisation', params: {}, query: {}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 12
    {
      text:
        'Access your Profile and personal settings here.<br><br><br>',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:last-child > div:nth-child(3)'
      },
      router: {name: 'profile-overview', params: {}, query: {tab: 'General'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: -155}},
      arrow: {side: 'left', position: 'bottom'},
      action: false,
      next: 'Next'
    }
  ],
  admin: [
    // 1
    {
      text:
        'This is your Requests section, where new<br>' +
        'customer requests will appear.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(2)'
      },
      router: {name: 'inbox', params: {}, query: {type: 'inbox', subtype: 'all'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 2
    {
      text:
        'This is your Chats section where all ongoing<br>' +
        'conversations or cases will appear. After you<br>' +
        'or a teammate accepts a request, it<br>' +
        'automatically moves to chats.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(3)'
      },
      router: {name: 'active-chat', params: {}, query: {type: 'active', subtype: 'personal'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 3
    {
      text:
        'This is your Contacts section. Contacts will<br>' +
        'appear in your business directory and will be<br>' +
        'used so your associates and customers can<br>' +
        'interact with the right individuals. Get started<br>' +
        'by creating contacts for yourself and your<br>'+ 
        'teammates!', 
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(4)'
      },
      router: {name: 'directory', params: {}, query: {}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 4
    {
      text:
        'First, create an individual contact for each<br>' +
        'teammate. You will also be able to create<br>' +
        'group contacts for multiple individuals.',
      querySelector: {
        relative: '.sidebar-contacts',
        selected: '.sidebar-contacts > header button'
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {x: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: -10}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 5
    {
      text:
        'View and edit your Business Directory in this<br>' +
        'window. Starting organizing it by creating<br>' +
        'departments and sub-departments.',
      querySelector: {
        relative: '[data-block=directoryTree]',
        selected: null
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {left: -410}, top: 100},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 6
    {
      text:
        'As an Admin, you can also access the Reports<br>' +
        'section for your company’s KPIs, performance<br>' +
        'data, and insights. Open and closed cases are<br>' +
        'also tracked here. Data is updated every night.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(5)'
      },
      router: {name: 'dashboard', params: {}, query: {}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 7
    {
      text:
        'This is your Business section where you can<br>' +
        'see and manage what teammates are part of<br>' +
        'this workspace, as well as manage your<br>' +
        'company profile.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(6)'
      },
      router: {name: 'users', params: {}, query: {tab: '0'}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 8
    {
      text:
        'To invite new teammates, you can use one of<br>' +
        'these options: Invite by email or Add manually.<br>' +
        'The main difference is Add manually requires<br>' +
        'more information up front by you, whereas<br>' +
        'Invite by email only requires you to enter their<br>' +
        'email. With both, the users still need to accept<br>' +
        'their invite and create an account.',
      querySelector: {
        relative: '[data-block=usersTopAction]',
        selected: null
      },
      router: {name: 'users', params: {}, query: {tab: '0'}},
      backgrounds: [
        {left: 0, width: {left: -10}, top: 0, bottom: 0},
        {left: {right: 10}, right: 0, top: 0, bottom: 0},
        {left: {left: -10}, width: {width: 20}, top: 0, height: {top: -10}},
        {left: {left: -10}, width: {width: 20}, top: {bottom: 10}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {left: -430}, top: {top: -5}},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 9
    {
      text:
        'Activated tab shows users who accepted<br>' +
        'your invite. Invited by email shows users who<br>' +
        'were invited by email but have not yet<br>' +
        'accepted the invite. Added manually shows<br>' +
        'users who you added manually but have not<br>' +
        'yet created their account.',
      querySelector: {
        relative: 'section.main > .tabs > nav > ul',
        selected: null
      },
      router: {name: 'users', params: {}, query: {tab: '0'}},
      backgrounds: [
        {left: 0, width: {left: -10}, top: 0, bottom: 0},
        {left: {right: 10}, right: 0, top: 0, bottom: 0},
        {left: {left: -10}, width: {width: 20}, top: 0, height: {top: -10}},
        {left: {left: -10}, width: {width: 20}, top: {bottom: 10}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 25}, top: {top: -5}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 10
    {
      text:
        'Since you created a new business your role<br>' +
        'by default is Admin.',
      querySelector: {
        relative: '[data-admin=true]',
        selected: '[data-admin=true] > td:nth-child(4)'
      },
      router: {name: 'users', params: {}, query: {tab: 0}},
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: {left: 0}, width: {width: 0}, top: 0, height: {top: 0}},
        {left: {left: 0}, width: {width: 0}, top: {bottom: 0}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {_left: 0}, top: {bottom: 10}},
      arrow: {side: 'top', position: 'left'},
      action: false,
      next: 'Next'
    },
    // 11
    {
      text:
        'The Settings section allows you to edit<br>' +
        'settings like time zone, schedules, backup<br>' +
        'contacts and more.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(7)'
      },
      router: {name: 'settings-localisation', params: {}, query: {}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 12
    {
      text:
        'Access your Profile and personal settings here.<br><br><br>',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:last-child > div:nth-child(3)'
      },
      router: {name: 'profile-overview', params: {}, query: {tab: 'General'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: -155}},
      arrow: {side: 'left', position: 'bottom'},
      action: false,
      next: 'Next'
    },


    // 1
    {
      text:
        'This is your Contacts <i></i> section. Get started<br>' +
        'by creating contacts for yourself and your team<br>' +
        'mates that are part of this workspace. Contacts<br>' +
        'are how your associates and customers will find<br>' +
        'and interact with the right individuals.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(4)'
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 2
    {
      text:
        'First, create an individual contact for each<br>' +
        'teammate. You will also be able to create<br>' +
        'group contacts for multiple individuals.',
      querySelector: {
        relative: '.sidebar-contacts',
        selected: '.sidebar-contacts > header button'
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {x: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: -10}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next: Create new contact'
    },
    // 3
    {
      text:
        'Great! You created your first contact. Now internal<br>\n' +
        'team mates can communicate with them in Pigeon.',
      querySelector: {
        relative: '.sidebar-contacts',
        selected: '.sidebar-contacts [data-tour=selected]'
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {x: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: {left: 0}, width: {width: 0}, top: 0, height: {_top: 0}},
        {left: {left: 0}, width: {width: 0}, top: {_bottom: 0}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {_right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: true,
      next: 'Next'
    },
    // 4
    {
      text:
        'Change them to visible so customers can<br>\n' +
        'communicate with them.',
      querySelector: {
        relative: '.sidebar-contacts [data-tour=selected] .invisible-wrapper',
        selected: null
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: {left: 16}, right: 0, top: 0, bottom: 0},
        {left: {left: 0}, width: 16, top: 0, height: {top: 0}},
        {left: {left: 0}, width: 16, top: {bottom: 0}, bottom: 0},
        {left: {left: 0}, width: 16, top: {top: 0}, height: 16, opacity: '0%'}
      ],
      position: {left: {right: 30}, top: {top: -20}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next: Contact Profile'
    },
    // 5
    {
      text:
        'Select Make visible so this contact will<br>' +
        'appear in your business directory in the Pigeon<br>' +
        'Customer app. Once they\'re visible, customers<br>' +
        'will be able to send them new requests and<br>' +
        'messages.',
      querySelector: {
        relative: '.contact-info-header .icon-menu',
        selected: null
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: true,
      backgrounds: [
        {left: 0, width: {left: -5}, top: 0, bottom: 0},
        {left: {right: 70}, right: 0, top: 0, bottom: 0},
        {left: {left: -5}, width: 107, top: 0, height: {top: -5}},
        {left: {left: -5}, width: 107, top: {top: 147}, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: -455}, top: {top: 54}},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next: Make visible'
    },
    // 6
    {
      text:
        'View and edit your Business Directory <i></i> in this<br>' +
        'window. Starting organizing it by creating<br>' +
        'departments and sub-departments.',
      querySelector: {
        relative: '[data-block=directoryTree]',
        selected: null
      },
      router: {name: 'directory', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {left: -410}, top: 100},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 7
    {
      text:
        'This is your Requests <i></i> section, where<br>\n' +
        'new customer requests will appear.<br>\n' +
        'Looks like someone sent you first request.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(2)'
      },
      router: {name: 'inbox', params: {}, query: {type: 'inbox', subtype: 'all'}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 8
    {
      text:
        'Check out your new request.',
      querySelector: {
        relative: '[data-block=activeChatList]',
        selected: '[data-block=activeChatList] [data-tour=selected]'
      },
      router: {},
      dynamicRouter: true,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 9
    {
      text:
        'You can Accept, Reject or Forward request.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'inbox', params: {}, query: {type: 'inbox', subtype: 'personal'}},
      dynamicRouter: true,
      backgrounds: [
        {left: 0, width: {left: -20}, top: 0, bottom: 0},
        {left: {right: 20}, right: 0, top: 0, bottom: 0},
        {left: {left: -20}, width: {width: 40}, top: 0, height: {_top: -20}},
        {left: {left: -20}, width: {width: 40}, top: {bottom: 20}, bottom: 0,},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {_left: -100}, top: {_top: -200}},
      arrow: {side: 'bottom', position: 'center'},
      action: false,
      next: 'Next: Accept'
    },
    // 10
    {
      text:
        'This is your Chats <i></i> section where all ongoing<br>' +
        'conversations or cases will appear. After you<br>' +
        'or a team mate accepts a request, it<br>' +
        'automatically moves to chats.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'active-chat', params: {}, query: {type: 'active', subtype: 'personal'}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 11
    {
      text:
        'You have a new message in an External chat. \n' +
        'An External chat is any chat that includes ' +
        'a customer. Chats with just team mates will be ' +
        'in Internal. ' +
        'You\'ll also notice the folders on the left that ' +
        'show All and My chats. Because you\'re an Admin, ' +
        'you can oversee the rest of your team\'s cases. ' +
        'All contains cases assigned to your team mates. ' +
        'Mine contains your personal cases.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {},
      dynamicRouter: true,
      backgrounds: [
        {left: 0, width: 64, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: 150},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 12
    {
      text:
        'You can send text messages, images, contacts,\n' +
        'location and more.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {},
      dynamicRouter: true,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: {left: 0}, width: {width: 0}, top: 0, height: {top: 0}},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {left: 50}, bottom: {height: 10}},
      arrow: {side: 'bottom', position: 'center'},
      action: false,
      next: 'Next'
    },
    // 13
    {
      text:
        'This sidebar shows Case Information <i></i>\n' +
        'It contains essential information about the case\n' +
        'and customer, as well as actions you can take.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {},
      dynamicRouter: true,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {right: {width: 10}, top: 20},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 14
    {
      text:
        'After the case is solved, you can Close case\n' +
        'to move it out of your Chats and into Archive.\n',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {},
      dynamicRouter: true,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {right: {width: 10}, top: {_top: -120}},
      arrow: {side: 'right', position: 'bottom'},
      action: false,
      next: 'Next: Close case'
    },
    // 15
    {
      text:
        'All closed cases are stored in Archive <i></i>\n' +
        'Mine <i></i> contains your personal cases and All <i></i>\n' +
        'contains cases from the rest of your team mates.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'archive', params: {}, query: {type: 'archive', subtype: 'personal'}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {left: 0}, top: 0, bottom: 0},
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: -145}},
      arrow: {side: 'left', position: 'bottom'},
      action: false,
      next: 'Next'
    },
    // 16
    {
      text:
        'As an Admin, you can also access the Reports\n' +
        'section for your company’s KPIs, performance\n' +
        'data, and insights. Every time you or your\n' +
        'associates close or open a case it is tracked here.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'dashboard', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 20}, top: {top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 17
    {
      text:
        'The Business section is where you manage\n' +
        'your company profile and users.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'users', params: {}, query: {tab: '0'}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 20}, top: {top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 18
    {
      text:
        'To invite new team mates, you can use one of\n' +
        'these options: Invite by email or Add manually.\n' +
        'The main difference is Add manually requires\n' +
        'more information up front by you, whereas\n' +
        'Invite by email only requires you to enter their\n' +
        'email. With both, the users still need to accept\n' +
        'their invite and create an account.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'users', params: {}, query: {tab: '0'}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {left: -10}, top: 0, bottom: 0},
        {left: {right: 10}, right: 0, top: 0, bottom: 0},
        {left: {left: -10}, width: {width: 20}, top: 0, height: {top: -10}},
        {left: {left: -10}, width: {width: 20}, top: {bottom: 10}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {left: -430}, top: {top: -5}},
      arrow: {side: 'right', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 19
    {
      text:
        'Activated tab shows users who accepted\n' +
        'your invite. Invited by email shows users who\n' +
        'were invited by email but have not yet\n' +
        'accepted the invite. Added manually shows\n' +
        'users who you added manually but have not\n' +
        'yet created their account.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'users', params: {}, query: {tab: '0'}},
      dynamicRouter: false,
      backgrounds: [
        {left: 0, width: {left: -10}, top: 0, bottom: 0},
        {left: {right: 10}, right: 0, top: 0, bottom: 0},
        {left: {left: -10}, width: {width: 20}, top: 0, height: {top: -10}},
        {left: {left: -10}, width: {width: 20}, top: {bottom: 10}, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 25}, top: {top: -5}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 20
    {
      text:
        'The Settings section allows you to edit settings ' +
        'like time zone, schedules, backup ' +
        'contacts and more.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'settings-localisation', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 20}, top: {top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 21
    {
      text:
        'Access your Profile and personal settings here.<br><br><br>',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: 'profile-overview', params: {}, query: {tab: 'General'}},
      dynamicRouter: false,
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 20}, top: {top: -155}},
      arrow: {side: 'left', position: 'bottom'},
      action: false,
      next: 'Next'
    }
  ],
  associate: [
    // 1
    {
      text:
        'This is your Requests section, where new<br>' +
        'customer requests will appear.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(2)'
      },
      router: {name: 'inbox', params: {}, query: {type: 'inbox', subtype: 'all'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: 0, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 2
    {
      text:
        'This is your Chats section where all ongoing<br>' +
        'conversations or cases will appear. After you<br>' +
        'accept a request, it automatically moves to<br>' +
        'chats.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(3)'
      },
      router: {name: 'active-chat', params: {}, query: {type: 'active', subtype: 'personal'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 3
    {
      text:
        'This is your Contacts section. Contacts are how<br>' +
        'you and your teammates and customers<br>' +
        'will find and interact with the right individuals.',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:first-child > div:nth-child(4)'
      },
      router: {name: 'directory', params: {}, query: {}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 10}, top: {_top: 0}},
      arrow: {side: 'left', position: 'top'},
      action: false,
      next: 'Next'
    },
    // 4
    {
      text:
        'Access your Profile and personal settings here.<br><br><br>',
      querySelector: {
        relative: '> div > .navigation-index',
        selected: '> div > .navigation-index > .v-navigation-drawer__content > div > div:last-child > div:nth-child(3)'
      },
      router: {name: 'profile-overview', params: {}, query: {tab: 'General'}},
      backgrounds: [
        {left: {right: 0}, right: 0, top: 0, bottom: 0},
        {left: 0, right: {right: 0}, top: 0, bottom: 0, opacity: '0%'}
      ],
      position: {left: {right: 20}, top: {_top: -155}},
      arrow: {side: 'left', position: 'bottom'},
      action: false,
      next: 'Next'
    },
    // 1
    {
      text:
        'This is your Requests section, where new customer requests will appear. Looks like someone sent you first request.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 2
    {
      text:
        'Check out your new request.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 3
    {
      text:
        'You can Accept, Reject or Forward request.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 4
    {
      text:
        'This is your Chats section where all ongoing<br>' + 
        'conversations or cases will appear. After you<br>' + 
        'accept a request, it automatically moves to<br>' +
        'chats.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 5
    {
      text:
        'You have a new message in an External chat. An External chat is any chat that includes a customer. Chats with just team mates will be in Internal.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 6
    {
      text:
        'You can send text messages, images, contacts, location and more.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 7
    {
      text:
        'This sidebar shows Case Information. It contains essential information about the case and customer, as well as actions you can take.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 8
    {
      text:
        'After the case is solved, you can Close case to move it out of your Chats and into Archive.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 9
    {
      text:
        'All closed cases are stored in Archive Mine contains your personal cases and All contains cases from the rest of your team mates.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 10
    {
      text:
        'This is your Contacts section. Contacts are how you and your teammates and customers will find and interact with the right individuals.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 11
    {
      text:
        'This is your Business Directory. Here you can find your teammates contacts list. My contacts your personal contacts list. Shared any contacts that have been shared with you by other teammates.',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    },
    // 12
    {
      text:
        'Access your Profile and personal settings here.<br><br><br>',
      querySelector: {
        relative: null,
        selected: null
      },
      router: {name: '', params: {}, query: {}},
      dynamicRouter: false,
      backgrounds: [],
      position: {},
      arrow: {side: '', position: ''},
      action: false,
      next: 'Next'
    }
  ]
}

const learnMoreLinks = [
  {
    step: 1,
    url: 'https://pigeonplatform.notion.site/Requests-Accept-Reject-Forward-or-Change-Priority-6e49f768e1354432a905112030dfc165'
  },
  {
    step: 2,
    url: 'https://pigeonplatform.notion.site/Create-a-New-Chat-or-New-Group-Chat-cdd13ea74fa04d9da5b5ebd3b6ef732d'
  },
  {
    step: 3,
    url: 'https://pigeonplatform.notion.site/Contacts-vs-Directory-vs-Shared-Contacts-vs-My-Contacts-vs-Shared-be974f277fd349f1840c3f17c8d97909'
  },
  {
    step: 4,
    url: 'https://pigeonplatform.notion.site/Create-a-Business-Directory-8c38ffa0580d43d4b058ef30a0059d5a'
  },
  {
    step: 5,
    url: 'https://pigeonplatform.notion.site/Create-a-Business-Directory-8c38ffa0580d43d4b058ef30a0059d5a'
  },
  {
    step: 6,
    url: 'https://pigeonplatform.notion.site/Pigeon-Platform-Help-Center-aa58fa25b2d148348bafa1cc73839a1c'
  },
  {
    step: 7,
    url: 'https://pigeonplatform.notion.site/Business-Management-04302da125dc4b79ad98f7f9ccc089cc'
  },
  {
    step: 8,
    url: 'https://pigeonplatform.notion.site/Invite-Team-Members-2655185f82bb4315825675e7fd80f567'
  },
  {
    step: 9,
    url: 'https://pigeonplatform.notion.site/View-Activated-Users-ccfae9378c1247c6a315e75e52b5af4d'
  },
  {
    step: 10,
    url: 'https://pigeonplatform.notion.site/Remove-Disable-or-Enable-a-User-2879be295d6945a4a37f9cedff9eaf94'
  },
  {
    step: 11,
    url: 'https://pigeonplatform.notion.site/Localization-48503f29845e4856833fc9b3e7164f61'
  },
  {
    step: 12,
    url: 'https://pigeonplatform.notion.site/User-Profile-Overview-0641b8d4cb0e40fda6924e55425901c4'
  }
];

@Module({name: 'tour-module', store, dynamic: true})
export default class TourModule extends VuexModule {
  private _superAdminSteps: number = 12
  private _adminSteps: number = 12
  private _associateSteps: number = 4
  private _tourNumber: number = 0
  private _tourRelativeObj: null | DOMRect = null
  private _tourSelectedObj: null | DOMRect = null
  private _tourDynamicRouter: null | any = null
  private _tourBusy: boolean = false
  private _tourAction: boolean = false
  private _tourAssociate: BusinessContact | null = null
  private _tourTextSessionId: string | null = null
  private _tourAddition: null | any = null
  private _tourCompleted: boolean = false
  private _learnMoreLink: string | null = null

  get tour() {
    if (this.tourNumber > 0) {
      if (profileStore.t2bUser?.roles?.superAdmin) {
        return defaultTourProperty.superAdmin[this.tourNumber - 1]
      } else if (profileStore.isAdmin) {
        return defaultTourProperty.admin[this.tourNumber - 1]
      } else {
        return defaultTourProperty.associate[this.tourNumber - 1]
      }
    }
    return defaultTourProperty.emptyTour
  }

  get tourNumber() {
    return this._tourNumber
  }

  get tourBusy() {
    return this._tourBusy
  }

  get tourLimit() {
    if (profileStore.t2bUser?.roles?.superAdmin) {
      return this._superAdminSteps
    } else if (profileStore.isAdmin) {
      return this._adminSteps
    } else {
      return this._associateSteps
    }
  }

  get tourRelativeObj() {
    return this._tourRelativeObj
  }

  get tourSelectedObj() {
    return this._tourSelectedObj
  }

  get tourDynamicRouter() {
    return this._tourDynamicRouter
  }

  get tourAction() {
    return this._tourAction
  }

  get tourAssociate() {
    return this._tourAssociate
  }

  get tourTextSessionId() {
    return this._tourTextSessionId
  }

  get tourAddition() {
    return this._tourAddition
  }

  get tourCompleted() {
    return this._tourCompleted
  }

  get learnMoreLink() {
    return this._learnMoreLink
  }

  @Mutation
  public setTour(tourNumber: number) {
    this._tourNumber = tourNumber
  }

  @Mutation
  public setTourRelativeObj(relativeObj: DOMRect | null) {
    this._tourRelativeObj = relativeObj
  }

  @Mutation
  public setTourSelectedObj(selectedObj: DOMRect | null) {
    this._tourSelectedObj = selectedObj
  }

  @Mutation
  public setDynamicRouter(router: any) {
    this._tourDynamicRouter = router
  }

  @Mutation
  public setTourBusy(busy: boolean) {
    this._tourBusy = busy
  }

  @Mutation
  public setTourAction(action: boolean) {
    this._tourAction = action
  }

  @Mutation
  public setTourAssociate(contact: BusinessContact | null) {
    this._tourAssociate = contact
  }

  @Mutation
  public setTourTextSessionId(id: string) {
    this._tourTextSessionId = id
  }

  @Mutation
  public setTourAddition(addition: any | null) {
    this._tourAddition = addition
  }

  @Mutation
  public setTourCompleted(completed: boolean) {
    this._tourCompleted = completed
  }

  @Mutation
  public setLearnMoreLink(tourNumber: number) {
    const learnMore = learnMoreLinks.find(lm => lm.step === tourNumber);
    this._learnMoreLink = learnMore ? learnMore.url : null;
  }

  @Action
  public changeTour(tourNumber: number) {
    if (tourNumber > 0) {
      this.setTourBusy(true)
    }
    this.setTourRelativeObj(null)
    this.setTourSelectedObj(null)
    this.setTourAddition(null)
    if (tourNumber <= this.tourLimit) {
      this.setTourCompleted(false)
      this.setTour(tourNumber)
      this.setTourAction(this.tour.action)
      this.setLearnMoreLink(tourNumber);
    } else {
      this.setTourCompleted(true)
      this.setTourBusy(false)
      this.setTour(0)
      this.setLearnMoreLink(0);
    }
  }

  @Action
  public updateTourRelativeObj(relativeObj: DOMRect) {
    this.setTourRelativeObj(relativeObj)
  }

  @Action
  public updateTourSelectedObj(selectedObj: DOMRect) {
    this.setTourSelectedObj(selectedObj)
  }

  @Action
  public async createChat() {
    const businessId = applicationStore.businessId;
    if (!businessId) {
      return
    }

    const currentUser = auth.currentUser;
    if (!this.tourAssociate) {
      return
    }

    try {
      const token = await currentUser?.getIdToken(false);
      const axiosResponse = await axios.post(`/TourService.CreateActive`,
        {businessId, associateId: this.tourAssociate!.id},
        {
          headers: {Authorization: `Bearer ${token}`}
        })
      if (axiosResponse?.data?.status === 'OK') {
        this.setTourTextSessionId(axiosResponse.data.textSessionId)
        return axiosResponse.data
      }
    } catch (err) {
      console.error(err)
    }
    return null
  }

  // todo: check this (refactoring)
  @Action
  public checkTour(tourCheck: { tourNumber: number, role: number }): boolean {
    const {tourNumber, role} = tourCheck
    if (tourNumber === this.tourNumber) {
      const roles = profileStore.t2bUser?.roles;
      if (role === RolesList.superAdmin && typeof roles?.superAdmin !== 'undefined') {
        return roles?.superAdmin
      } else if (role === RolesList.admin) {
        return roles?.admin || profileStore.t2bUser?.admin
      } else {
        return true
      }
    }
    return false
  }
}

export const tourStore = getModule(TourModule)
