
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PopupMenuBody from '@/components/PopupMenuBody.vue';
import {getAbsoluteBoundingRect} from "@/utils/helpers";

@Component({
  name: 'popup-menu',
  components: {PopupMenuBody}
})
export default class PopupMenu extends Vue {
  @Prop({type: Array}) items
  @Prop({type: Boolean, default: false}) topRight
  @Prop({type: Boolean, default: false}) right
  @Prop({type: Boolean, default: false}) vert
  @Prop({type: Boolean, default: false}) small
  @Prop({type: Boolean, default: false}) scroll
  @Prop({type: Boolean, default: false}) open

  show: boolean = false
  menuTop: number = 0
  menuLeft: number = 0
  menuRight: number = 0
  child: any | null = null

  on() {
    if (this.child) {
      return
    }
    const menu = this.$refs.menu as HTMLElement;
    const rect = menu.getBoundingClientRect();
    this.menuTop = rect.bottom
    this.menuLeft = rect.left
    this.menuRight = rect.right

    const menuBody = Vue.extend(PopupMenuBody)
    this.child = new menuBody({
      propsData: {
        items: this.items,
        right: this.right,
        small: this.small,
        menuTop: this.menuTop,
        menuLeft: this.menuLeft,
        menuRight: this.menuRight
      }
    })
        .$mount()
        .$on('item-click', this.itemClicked)
    this.appRoot?.appendChild(this.child.$el)
    this.show = !this.show
  }

  itemClicked(item) {
    this.dismiss()
    this.$emit('item-click', item)
  }

  get appRoot() {
    return document.getElementById('app')
  }

  dismiss(event: any = null) {
    this.show = false
    if (this.child) {
      this.appRoot?.removeChild(this.child?.$el)
      this.child?.$destroy()
      this.child = null
    }
  }

  mounted() {
    if (this.open) {
      this.on()
    }
  }
}
