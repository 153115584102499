

















import {Component, Vue} from 'vue-property-decorator'

@Component({
  name: 'gp-tabs',
})
export default class TabsView extends Vue {
};
