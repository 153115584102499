





import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({name: 'gp-tab'})
export default class TabView extends Vue {
  @Prop({type: Boolean}) tab;

  //  :class="{ selected: isSelected }"
  // @Prop({type: String}) id?;
  // @Prop({type: String}) selected?;
  //
  // get isSelected() {
  //   return this.selected == this.id;
  // }
  // onClick () {
  //   this.selected = this.id;
  // }
};
